<template>
  <section class="single_product">
    <div class="container tbl flx">
      <div class="about_product" v-if="product">
        <div class="images_product">
          <div class="slider_wrap flx" v-if="product && product.properties && product.properties.images">
            <div class="wrap-slider-nav" v-if="product.properties.images.length > 0">
              <vue-slick-carousel
                style="overflow-x: hidden"
                class="slider-nav"
                ref="c2"
                :asNavFor="$refs.c1"
                :focusOnSelect="true"
                @beforeChange="syncSliders"
                v-bind="settings"
              >
                <div :key="index" v-for="(image, index) in product.properties.images">
                  <div class="slider-nav--item">
                    <img :src="image.links[68]" :alt="product.name">
                  </div>
                </div>
              </vue-slick-carousel>
              <div class="custom-arrow-images flx">
                <button id="prev" type="button" class="btn-juliet slick-arrow" style="" @click="showPrev"><i
                  class="fa fa-left" aria-hidden="true"> </i></button>
                <button id="next" type="button" class="btn-juliet slick-arrow" style="" @click="showNext"><i
                  class="fa fa-right" aria-hidden="true"> </i></button>
              </div>
            </div>
            <div class="wrap-slider-for">
              <vue-slick-carousel v-if="product.properties.images.length > 0"
                class="slider-for"
                ref="c1"
                :asNavFor="$refs.c2"
                :focusOnSelect="true"
                @beforeChange="syncSliders"
                v-bind="image_view_settings"
              >
                <div :key="index" v-for="(image, index) in product.properties.images">
                  <div class="slider-for--item cursor-pointer" @click="img_index = index">
                    <img :src="image.links[280]">
                  </div>
                </div>
              </vue-slick-carousel>
              <img v-else src="/images/no-img.svg" alt="">
            </div>
          </div>
          <div class="view_wrap" v-if="user">
            <span class="photo_ico"></span>
            <span class="download_photo" @click="downloadImages(product.id)">Скачать все изображения товара</span>
          </div>
        </div>

        <div class="head_product">
          <!--          TODO breadcrumbs -->
          <div class="breadcrumbs_wrap">
            <a href="/">Магазин</a>
            <router-link
              v-for="(bread_crumb, index) in product.bread_crumbs" :key="index"
              :to="bread_crumb.url"
            >
              {{ bread_crumb.name }}
            </router-link>
          </div>
          <div class="top flx">
            <h2>{{ product.name }}</h2>
            <span class="to_favorites" :class="{'is_favorite':product.is_favorite}" v-if="user"
                  @click="toggleFavorites(product.id)">
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.61252 1.2653L8.61266 1.265C8.65398 1.17761 8.71506 1.11003 8.7838 1.06545C8.85208 1.02116 8.92685 1 8.99998 1C9.07311 1 9.14788 1.02116 9.21616 1.06545C9.2849 1.11003 9.34598 1.17761 9.3873 1.265L9.38737 1.26515L11.3888 5.49643C11.5312 5.79748 11.8141 6.00789 12.1434 6.05763L16.6173 6.73337C16.6904 6.74444 16.7638 6.77722 16.8279 6.83366C16.8925 6.89045 16.9453 6.96936 16.9748 7.06436C17.0045 7.15955 17.0081 7.26275 16.9849 7.36074C16.9616 7.45858 16.9138 7.54257 16.852 7.60528L16.8516 7.60572L13.6137 10.8965C13.3897 11.1242 13.2879 11.4451 13.3398 11.7602L14.1045 16.4072C14.1046 16.4073 14.1046 16.4075 14.1046 16.4077C14.1208 16.5075 14.1097 16.6096 14.0741 16.7009C14.0384 16.7923 13.9812 16.8655 13.9145 16.9161C13.8481 16.9664 13.774 16.9933 13.701 16.9989C13.6282 17.0044 13.5534 16.9891 13.4839 16.9513C13.4837 16.9511 13.4835 16.951 13.4833 16.9509L9.47965 14.7569C9.18021 14.5929 8.81775 14.5929 8.51835 14.757L4.51664 16.9509C4.51646 16.951 4.51628 16.9511 4.51611 16.9512C4.4466 16.9891 4.37176 17.0044 4.299 16.9989C4.22596 16.9933 4.15186 16.9664 4.0855 16.9161C4.01871 16.8655 3.96157 16.7923 3.92591 16.7009C3.8902 16.6095 3.87911 16.5074 3.89537 16.4075C3.89539 16.4074 3.89541 16.4073 3.89543 16.4072L4.65843 11.7599C4.71013 11.4449 4.60842 11.1243 4.38466 10.8967L1.14742 7.60468C1.14733 7.60458 1.14723 7.60449 1.14714 7.60439C1.08562 7.54161 1.03802 7.4577 1.01498 7.36004C0.991879 7.26209 0.995629 7.15899 1.0253 7.06391C1.05492 6.96902 1.10769 6.89023 1.17222 6.83353C1.23634 6.77718 1.30966 6.74443 1.38265 6.73337C1.38266 6.73337 1.38267 6.73337 1.38267 6.73337L5.85698 6.05569C6.18605 6.00585 6.46877 5.79555 6.61113 5.4947L8.61252 1.2653Z"
                  fill="transparent" stroke="#F5A32A" stroke-width="2" stroke-linejoin="round"/>
              </svg>
            </span>
          </div>
          <div class="sub_info flx">
            <!--            <span class="information">Показать Важную информацию по товару</span>-->
            <span class="grey">Артикул: {{ product.vendor_code }}</span>
          </div>
        </div>
        <div class="info_product">
          <div class="price_outer flx" v-if="user">
            <span class="price_inner" v-if="getDiscountByColor > 0">
              <span class="old_price" v-if="getDiscountByColor > 0">{{ getRetailPriceByColor }}</span>
              <span class="new_price">{{ getDiscountByColor }}</span>  грн
            </span>
            <span class="price_inner" v-else>
              <span class="new_price">{{ getRetailPriceByColor }}</span>  грн
            </span>
            <span class="download_info">
                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7.00044 13.5492C7.5375 13.5492 7.97288 13.1278 7.97288 12.608L7.97288 0.941416C7.97288 0.421621 7.53751 0.000242798 7.00044 0.000242845C6.46339 0.000242892 6.02801 0.421621 6.02801 0.941416L6.02801 12.6081C6.02801 13.1278 6.46338 13.5492 7.00044 13.5492Z"
                        fill="#ADB8C6"/>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M8.71394e-07 15.0591C9.16836e-07 15.5789 0.435375 16.0002 0.972435 16.0002L13.0266 16.0002C13.5636 16.0002 13.999 15.5789 13.999 15.0591C13.999 14.5393 13.5636 14.1179 13.0266 14.1179L0.972435 14.1179C0.435375 14.1179 8.25952e-07 14.5393 8.71394e-07 15.0591Z"
                        fill="#ADB8C6"/>
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M13.7152 6.10921C13.3354 5.74166 12.7197 5.74166 12.34 6.10921L7.0005 11.277L1.66104 6.10921C1.28128 5.74165 0.66557 5.74165 0.285811 6.10921C-0.0939473 6.47676 -0.0939472 7.07267 0.285811 7.44022L6.31288 13.2735C6.69264 13.6411 7.30835 13.6411 7.68811 13.2735L13.7152 7.44022C14.0949 7.07267 14.0949 6.47676 13.7152 6.10921Z"
                        fill="#ADB8C6"/>
                </svg>
              </span>
          </div>
          <div class="row">
            <div class="data_item flx">
              <span class="name">Категория:</span>
              <p v-for="(category,index) in product.categories" :key="category.id">
                {{ category.name }}
                <span v-if="index < product.categories.length -1">&nbsp;·&nbsp;</span>
              </p>
            </div>
            <div class="data_item flx">
              <span class="name">Сезон:</span>
              <p v-for="(season, index) in product.seasons" :key="season.id">
                {{ season.name }}
                <span v-if="index < product.seasons.length -1">&nbsp;·&nbsp;</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="data_item flx">
              <span class="name">Цвет:</span>
              <div class="tabs_box flx" data-type="color-card">
                <div class="tab"
                     :key="color.id"
                     v-for="(color,index) in product.remains ? product.remains : []"
                     :class="{active : activeColor === index}"
                     @click="activeColor = index"
                >
                  <span :style="{'background':color.color ? color.color.primary_color : null }"
                        :class="{ 'black-border': color.color && color.color.name === 'Белый' }"></span>
                  <p>{{ color.color ? color.color.name : null }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <span class="wrap_name">Размер<span v-if="isAuth()"> · Наличие</span>: <span class="info"></span></span>
            <div class="size_wrap_outer flx">
              <div class="size_wrap">
                <div class="tab_container active flx" v-if="isAuth()">
                  <div class="size flx"
                       v-tooltip="(!size.remains && getWaitDate) ? 'Поставка ожидается: ' + getWaitDate :null"
                       :class="{pending: !size.remains, active: to_cart.findIndex(item => item.id === size.id) >= 0}"
                       v-for="size in getColorData"
                       :key="size.id"
                       @click="toggleToCart(size)"
                  >
                    <span>{{ size.size ? size.size.name : null }}</span>
                    <span :class="getColorRemains(size.remains)"
                          v-text="size.remains > 0 ? size.remains+'шт': ''">
                    </span>
                  </div>
                </div>
                <div class="tab_container active flx" v-else>
                  <div class="size flx"
                       :class="{pending: !size.remains }"
                       v-for="(size, index) in getColorData"
                       :key="index + size.size"
                  >
                    <span>{{ size.size ? size.size.name : null }}</span>
                    <span :class="getColorRemains(size.remains)"
                          v-text="size.remains > 0 ? size.remains+'шт': ''">
                    </span>
                  </div>
                </div>
              </div>
              <!--              TODO choose size-->
              <div class="choose_size" v-if="product.size_images">
                <span class="ruler"> </span>
                <a @click="$emit('setShowSizeModal', true)">Подобрать Размер</a>
              </div>
              <div class="choose_size" v-else>
                <span class="ruler"> </span>
                <a target="_blank" href="https://t.me/brewdrop1">Подобрать Размер</a>
              </div>
            </div>
          </div>
          <div class="row" v-if="isAuth()">
            <span class="wrap_name">Количество:</span>
            <div class="select_size_outer flx">
              <div class="select_size_inner" v-for="(size, idx) in to_cart" :key="size.id">
                <span style="vertical-align: middle">{{ size.size.name }}</span>
                <input class="select_item" type="number" v-model="to_cart[idx].qty" min="1" :max="size.remains">
              </div>
            </div>
          </div>
          <div class="row" v-if="user">
            <button class="btn" :class="{ disabled: disabledCart }" :disabled="disabledCart" @click="addToCart">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.7945 15.2C10.2668 15.2 10.7198 15.0103 11.0538 14.6728C11.3878 14.3352 11.5754 13.8774 11.5754 13.4C11.5754 12.9226 11.3878 12.4648 11.0538 12.1272C10.7198 11.7896 10.2668 11.6 9.7945 11.6C9.32218 11.6 8.86921 11.7896 8.53522 12.1272C8.20124 12.4648 8.01361 12.9226 8.01361 13.4C8.01361 13.8774 8.20124 14.3352 8.53522 14.6728C8.86921 15.0103 9.32218 15.2 9.7945 15.2ZM3.56139 15.2C4.03371 15.2 4.48669 15.0103 4.82067 14.6728C5.15465 14.3352 5.34228 13.8774 5.34228 13.4C5.34228 12.9226 5.15465 12.4648 4.82067 12.1272C4.48669 11.7896 4.03371 11.6 3.56139 11.6C3.08907 11.6 2.6361 11.7896 2.30211 12.1272C1.96813 12.4648 1.7805 12.9226 1.7805 13.4C1.7805 13.8774 1.96813 14.3352 2.30211 14.6728C2.6361 15.0103 3.08907 15.2 3.56139 15.2ZM15.171 2.53069C15.3933 2.52344 15.6041 2.4291 15.7588 2.2676C15.9135 2.10611 16 1.89013 16 1.66534C16 1.44055 15.9135 1.22457 15.7588 1.06307C15.6041 0.90158 15.3933 0.807234 15.171 0.799988H14.1461C13.3429 0.799988 12.6484 1.36339 12.4738 2.15539L11.3581 7.23319C11.1836 8.02519 10.489 8.58859 9.68587 8.58859H2.99685L1.71283 3.39559H10.034C10.2542 3.38543 10.4621 3.28987 10.6143 3.12878C10.7666 2.9677 10.8516 2.75348 10.8516 2.53069C10.8516 2.3079 10.7666 2.09368 10.6143 1.93259C10.4621 1.7715 10.2542 1.67594 10.034 1.66579H1.71283C1.45252 1.66571 1.19562 1.72562 0.961663 1.84096C0.727703 1.9563 0.52284 2.12403 0.362646 2.33141C0.202453 2.53879 0.0911467 2.78036 0.0371902 3.03774C-0.016768 3.29513 -0.0119549 3.56156 0.051261 3.81679L1.33528 9.00799C1.42785 9.38256 1.64167 9.7151 1.94275 9.95271C2.24383 10.1903 2.61486 10.3194 2.99685 10.3193H9.68587C10.4652 10.3194 11.2212 10.0508 11.8292 9.55798C12.4371 9.06512 12.8605 8.37743 13.0295 7.60849L14.1461 2.53069H15.171Z"
                  fill="white"/>
              </svg>
              <span>Добавить в корзину</span>
            </button>
          </div>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <CoolLightBox
      v-if="product"
      :items="product.properties.images"
      :index="img_index"
      @close="img_index = null">
    </CoolLightBox>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { mapActions, mapMutations, mapState } from 'vuex'
import { colors } from '../../../mixins/colors'

export default {
  name: 'ProductInfoTop',
  inject: ['isAuth', 'user_id'],
  props: {
    product: {
      type: Object || null,
      default: () => {
      }
    },
    color_id: {
      type: Number || null,
      default: null
    }
  },
  mixins: [colors],
  data () {
    return {
      show_size_modal: false,
      to_cart: [],
      img_index: null,
      settings: {
        centerMode: true,
        centerPadding: '45px',
        dots: false,
        lazyLoad: 'ondemand',
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        swipeToSlide: true
      },
      image_view_settings: {
        lazyLoad: 'ondemand',
        dots: false,
        fade: true,
        infinite: true,
        speed: 0,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  },
  watch: {
    product: {
      immediate: true,
      handler (val) {
        const color = parseInt(this.$route.query.color_id)

        if (isNaN(color) && val && val.remains.length) {
          this.setColorId(val.remains['0'].id)
          this.$router.replace({ query: { color_id: val.remains['0'].id } })
        }
      }
    }
  },
  components: {
    VueSlickCarousel,
    CoolLightBox
  },
  computed: {
    ...mapState('auth', ['user']),
    activeColor: {
      get () {
        return this.getColorIndex
      },
      set (val) {
        const color = this.product.remains[val]
        this.$emit('update:color_id', color.id)
      }
    },
    getColorIndex () {
      if (this.color_id && this.product.id && this.product.remains.findIndex(item => item.id === this.color_id) >= 0) {
        return this.product.remains.findIndex(item => item.id === this.color_id)
      }
      return 0
    },
    getRetailPriceByColor () {
      if (this.product.id && this.product.remains.indexOf(this.activeColor)) {
        return this.product.remains[this.activeColor].retail_price
      }
      return 0
    },
    getDiscountByColor () {
      if (this.product.id && this.product.remains.indexOf(this.activeColor)) {
        return this.product.remains[this.activeColor].discount_price ? this.product.remains[this.activeColor].discount_price : 0
      }
      return 0
    },
    getColorData () {
      if (this.product.id && this.product.remains.indexOf(this.activeColor)) {
        return this.product.remains[this.activeColor].sizes
      }
      return []
    },
    getWaitDate () {
      if (this.product.id && this.product.remains.length > 0 && this.product.remains.indexOf(this.activeColor)) {
        return this.product.remains[this.activeColor].wait_formatted
      }
      return 0
    },
    disabledCart () {
      return this.to_cart.length === 0
    }
  },
  methods: {
    ...mapActions('cart', ['addItemToCart']),
    ...mapActions('products', ['addToFavorites', 'removeFavorite', 'downloadImages']),
    ...mapMutations('products', ['setColorId']),
    syncSliders (currentPosition, nextPosition) {
      this.$refs.c1.goTo(nextPosition)
      this.$refs.c2.goTo(nextPosition)
    },
    showPrev () {
      this.$refs.c2.next()
    },
    showNext () {
      this.$refs.c2.next()
    },
    toggleToCart (size) {
      if (size.remains > 0) {
        const index = this.to_cart.findIndex(item => item.id === size.id)
        if (index >= 0) {
          this.$delete(this.to_cart, index)
        } else {
          size.qty = 1
          size.product_color_size_id = size.id
          this.to_cart.push(size)
        }
      }
    },
    addToCart () {
      this.to_cart.forEach(item => {
        item.user_id = this.user_id()
        this.addItemToCart(item)
      })
    },
    toggleFavorites (productId) {
      if (this.product.is_favorite) {
        this.removeFavorite(productId)
      } else {
        this.addToFavorites(productId)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.is_favorite svg path {
  fill: #f5a32a;
}
</style>
